<template>
  <div>
    <div class="card">
      <div class="d-flex justify-content-between p-1 align-items-center">
        <div>
          <h4><i class="fa fa-users" /> แก้ไขข้อมูลลูกค้า</h4>
        </div>

        <div>
          <button
            class="btn btn-gradient-info mr-1"
            @click="save"
          >
            <i class="fas fa-arrow-up" /> ประวัติการฝาก
          </button>
          <button
            class="btn btn-gradient-info mr-1"
            @click="cancel"
          >
            <i class="fas fa-arrow-down" /> ประวัติการถอน
          </button>
          <button
            class="btn btn-gradient-info mr-1"
            @click="cancel"
          >
            <i class="fas fa-magic" /> ประวัติโบนัส
          </button>
          <button
            class="btn btn-gradient-info"
            @click="cancel"
          >
            <i class="fas fa-users" /> เพื่อนที่แนะนำ
          </button>
        </div>
      </div>
      <hr class="m-0 p-0">
      <div class="mt-1 p-1">
        <!-- form group -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>ชื่อ <small class="text-danger">*</small></label>
              <input
                v-model="fname"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>นามสกุล <small class="text-danger">*</small></label>
              <input
                v-model="lname"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Agent Account ID</label>
              <input
                v-model="agent_acc"
                type="text"

                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Username</label>
              <input
                v-model="username"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>รหัสผ่านปัจจุบัน</label>
              <input
                v-model="password"
                disabled
                type="text"
                class="form-control text-danger font-weight-bolder"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>รหัสผ่านใหม่ <small class="text-danger">*</small></label>
              <input
                v-model="password_new"
                type="text"
                class="form-control text-success font-weight-bolder"
              >
              <small class="text-danger">รหัสผ่านประกอบด้วยตัวอักษรภาษอังกฤษและตัวเลข ตั้งแต่ 8 ตัวขึ้นไป</small>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>เบอร์โทรศัพท์ <strong class="text-danger">(099xxxxxxxx)</strong></label>
              <input
                v-model="password_new"
                type="text"
                class="form-control font-weight-bolder"
              >

            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>ธนาคาร</label>
              <v-select
                v-model="member"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="names"
                :options="optionsBank"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>ชื่อบัญชี <small class="text-danger">*</small></label>
              <input
                v-model="fname"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>เลขที่บัญชี <small class="text-danger">*</small></label>
              <input
                v-model="lname"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>เลขจับคู่ฝากออโต้ <small class="text-danger">*</small></label>
              <input
                v-model="agent_acc"
                type="text"

                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>ธนาคารสำหรับฝาก</label>
              <v-select
                v-model="member"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="names"
                :options="optionsBank"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>LINE ID <small class="text-danger">*</small></label>
              <input
                v-model="fname"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>หมายเหตุ <small class="text-danger">*</small></label>
              <textarea
                v-model="fname"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>เลือกผู้แนะนำ</label>
              <v-select
                v-model="member"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="names"
                :options="optionsBank"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>เลือกผู้แนะนำ</label>
              <b-form-checkbox
                checked="true"
                class="custom-control-secondary"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>

          <div class="col-md-12">
            <button class="btn btn-gradient-dark btn-block">
              <i class="fas fa-save" /> บันทึก
            </button>
          </div>

        </div>
      </div>
    </div>
  </div></template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      fname: '',
      lname: '',
      agent_acc: '',
      username: '',
      password: 'Aa123456',
      optionsBank: [
        { names: 'ธนาคารกรุงเทพ' },
        { names: 'ธนาคารกรุงไทย' },
        { names: 'ธนาคารกสิกรไทย' },
        { names: 'ธนาคารทหารไทยธนชาติ' },
        { names: 'ธนาคารไทยพาณิชย์' },
        { names: 'ธนาคารกรุงศรีอยุธยา' },
        { names: 'ธนาคารเกียรตินาคิน' },
        { names: 'ธนาคารซีไอเอ็มบีไทย' },
        { names: 'ธนาคารทิสโก้' },
        { names: 'ธนาคารธนชาต' },
        { names: 'ธนาคารยูโอบี' },
        { names: 'ธนาคารแลนด์ แอนด์ เฮ้าส์' },
        { names: 'ธนาคารไอซีบีซี (ไทย)' },
        { names: 'ธนาคารแห่งประเทศไทย' },
        { names: 'ธนาคารออมสิน' },
        { names: 'ธนาคารอาคารสงเคราะห์' },
        { names: 'ธนาคารอิสลามแห่งประเทศไทย' },
        { names: 'ธนาคารเอชเอ็นเอสซี' },
        { names: 'ธนาคารแห่งประเทศไทย' },
        { names: 'ธนาคารอิสลามแห่งประเทศไทย' },

      ],
    }
  },
}
</script>

<style scoped>
  label {
    font-size: 1rem !important;
    font-weight: 700;
  }
</style>
